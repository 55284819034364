@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Outfit:wght@400;500;600;700;800;900&display=swap");

body .listedBoxing .reviewTable.news td:nth-child(7) button {
  color: #475467 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #fff;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}
.controls {
  z-index: 9999999;
  position: absolute;
  bottom: 14px;
  width: 96%;
  left: 8px;
}
.controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}
input {
  font-family: "Inter" !important;
}
h5 {
  font-family: "Inter" !important;
}
.yearPicker .ant-picker-content .ant-picker-cell-inner {
  display: flex;
  width: 40px;
  color: #344054;
  font-family: "Inter" !important;
  font-size: 14px !important;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400 !important;
}
.yearPicker .ant-picker-content td {
  text-align: left !important;
}

.resetInput::placeholder {
  color: #667085;
  font-size: 16px;
  font-weight: 400;
}
.resetInput {
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-family: "Inter";
  border: 1px solid #d0d5dd !important;
  outline: 0 !important;
  border-radius: 8px;
  color: #667085;
  font-size: 16px;
  font-weight: 400;
}
.emailText {
  display: block;
  font-family: "Inter";
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 8px;
}
.btnDate.sz span {
  color: #667085 !important;
}
.tableModal.modalclass table thead th {
  font-size: 12px !important;
  color: #475467 !important;
  background: #f9fafb !important;
}
body .tableModal.modalclass table tbody td:nth-child(1) {
  color: #475467 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.payoutFooter h3 {
  color: #344054 !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}
.boxeds span {
  color: #667085 !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.boxeds {
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  height: 40px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
}
.tableModal.modalclass h3 {
  padding-bottom: 0 !important;
}
.payoutFooter button {
  border: 1px solid #d0d5dd !important;
  color: #344054 !important;
  font-family: "Inter" !important;
  font-weight: 600 !important;
}
.tableModal.modalclass table tbody td {
  color: #101828 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Inter" !important;
  height: 72px;
  border-bottom: 1px solid #eaecf0 !important;
}
h3.headPayment {
  font-size: 30px;
  font-family: "Inter";
  color: #101828;
  font-weight: 600;
}
body .rowes td .fontss_1 {
  font-weight: 600 !important;
}
.barGraph .ant-picker {
  height: 40px !important;
}
.lineGraph .ant-picker {
  height: 40px !important;
}
.barGraph .ant-picker-suffix svg {
  display: none !important;
}
.headerModal h3 {
  font-size: 18px;
  font-weight: 600;
  color: #101828;
  font-family: "Inter";
}
.headerModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  /* border-bottom: 1px solid #EAECF0; */
}
.ulBody li {
  height: 72px;
  border-top: 1px solid #eaecf0;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: #101828;
  font-weight: 500;
  font-family: "Inter";
  font-size: 14px;
}
.ulBody ul li .blue {
  background: #d9e6ff;
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #4885ff;
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}
.ulBody ul li .green {
  background: #aeffc5;
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #399318;
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}
.yellow {
  background: #fbf2a7;
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  color: #a99a22;
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;
}
.modalPartner span.ant-modal-close-x {
  display: none !important;
}
.modalPartner .ant-modal-content {
  padding: 0 !important;
}
.modalPartner {
  width: 380px !important;
}
.modalPartner .ant-modal-footer {
  display: none !important;
}
.lengths {
  font-size: 12px;
  color: #344054;
  font-family: "Inter";
  font-weight: 500;
}
.lineGraph .ant-picker-suffix svg {
  display: none !important;
}
.barGraph .ant-picker-suffix {
  background-image: url(./assets/images/cale.png);
  width: 18px;
  height: 20px;
}
.lineGraph .ant-picker-suffix {
  background-image: url(./assets/images/cale.png);
  width: 18px;
  height: 20px;
}
h1.eventHeading {
  font-size: 24px;
  color: #101828;
  font-family: "Inter";
  font-weight: 500;
  margin: 0;
  position: relative;
  top: 8px;
}
.selectedText {
  font-size: 14px !important;
  font-weight: 500;
  color: #475467 !important;
}
.checkedBtn span img {
  margin-right: 8px !important;
}
.checkedBtn span {
  display: flex;
  align-items: center;
}
.tableCollapse .Mui-checked svg {
  background-image: url(../src/assets/images/fill.png) !important;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  color: transparent !important;
}

.bigTableBody .MuiTableCell-root .MuiButtonBase-root.MuiCheckbox-root svg {
  background-image: url(../src//assets/images/checkbox.png);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1799px) and (min-width: 1260px) {
  body .tableCollapse {
    width: 1248px !important;
  }
  .userName.last h6 {
    width: auto !important;
  }
  .mobileFits {
    max-width: 71% !important;
    flex-basis: 71% !important;
  }
  .mobileFit {
    max-width: 29% !important;
    flex-basis: 29% !important;
  }
  body .sm_5 {
    width: 138px !important;
  }

  body .sm_4 {
    width: 143px !important;
  }

  body .tableCollapse thead tr th:nth-child(1) {
    width: 35px !important;
    max-width: 36px !important;
    min-width: 37px !important;
  }

  body .sm_3 {
    width: 205px !important;
  }

  body .sm_2 {
    width: 83px !important;
  }

  body .innerBodys .innerSection .sm_1 {
    width: 44px !important;
    max-width: 44px !important;
  }
  body .innerBodys .innerSection .sm_3 {
    width: 257px !important;
    max-width: 257px !important;
  }
  body .innerBodys .innerSection .sm_2 {
    width: 105px !important;
    max-width: 105px !important;
  }
}

.tableCollapse {
  width: 100% !important;
}

.profilePic {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  object-fit: cover;
}
.userName.last {
  text-align: end !important;
  width: auto !important;
}
.yellowBg {
  background: #fbf2a7;
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin-right: 10px;
  color: #a99a22;
  font-size: 16px;
}

.blueBg {
  background: #d9e6ff;
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin-right: 10px;
  color: #4885ff;
  font-size: 16px;
}

@media screen and (max-width: 1400px) and (min-width: 1000px) {
  .userName h6 {
    width: 78px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .userName h5 {
    width: 150px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
}

.btnDate {
  background: #fff;
  border: 1px solid #dcdbdd;
  border-radius: 8px;
  height: 40px;
  color: #667085;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  width: auto;
  font-family: "Inter, sans-serif";
}

.dropdownModal.input .MuiPickersToolbar-root {
  display: none !important;
}

.dropdownModal.input {
  position: relative;
}

.dropdownModal.input .MuiDialogActions-root {
  display: none !important;
}

.dropdownModal.input .MuiPickersLayout-root.MuiPickersLayout-landscape {
  box-shadow: 0px 0px 5px #ccc !important;
  border-radius: 12px !important;
  margin: 10px 0 !important;
  position: absolute !important;
  top: 0;
}

.modalHead {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.dropdownModal.input input {
  height: 43px;
  border: 1px solid #000;
  border-radius: 8px;
  font-size: 14px;
  font-family: Inter;
}

.modalHead h1 {
  font-size: 32px;
  color: #1e293b;
  font-weight: 500;
  font-family: Inter;
  margin-left: 12px;
}

.dropdownModal {
  margin-right: 8px !important;
}

.dropdownModal .MuiInputBase-root {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  font-family: Inter;
  font-size: 14px !important;
}

.dropdownModal fieldset {
  border: 1px solid #000 !important;
  border-radius: 8px !important;
}

.modalView {
  border-radius: 18px !important;
}

.modalView label {
  font-family: Inter;
  display: block;
  font-size: 16px;
  padding-bottom: 7px;
  font-weight: 500;
}

body .viewModal.employ {
  width: 985px !important;
}

.employeBox .ant-select {
  width: 361px !important;
  margin-left: 4px;
}

.addBtn-1 {
  background: #000 !important;
  height: 41px;
  border-radius: 11px;
  color: #fff !important;
  position: absolute;
  right: 0;
  margin: 0 !important;
}

.commisonModel .ant-select {
  width: 100% !important;
}

.viewHeader span {
  margin-left: 21px;
  font-size: 14px;
  color: #667085;
  font-family: "Inter";
  display: block;
  padding: 6px 0 0 !important;
}

.commisonModel .ant-select-selector {
  border-radius: 8px !important;
  color: #101828 !important;
  font-family: "Inter" !important;
}

.commisonModel .ant-select-selection-placeholder {
  color: #101828 !important;
  font-size: 16px;
}

button span {
  font-family: "Inter";
}

.commisonModel {
  margin: 0 5px 14px !important;
}

.badgeSpan {
  background: #e8e8e8;
  width: 66px;
  height: 24px;
  text-align: center;
  border-radius: 16px;
  font-family: "Inter";
  font-size: 14px;
  color: #525252 !important;
  font-weight: 500;
}

.rowes th {
  font-family: "Inter" !important;
  font-weight: 500 !important;
  color: #475467 !important;
}

.rowes td span {
  font-family: "Inter" !important;
  font-weight: 500 !important;
  /* color: #475467 ; */
}

.rowes td {
  font-family: "Inter" !important;
  font-weight: 500 !important;
  color: #475467 !important;
}

.filledBtn {
  background: #000 !important;
  height: 41px;
  border-radius: 11px;
  color: #fff !important;
  font-family: "Inter";
  margin: 14px 0 !important;
}

.viewModal {
  width: 594px !important;
  border-radius: 12px !important;
}

.viewModal .ant-modal-close-x {
  display: none !important;
}

.rowInput {
  margin: 0 5px;
}

.inputBoxedRow {
  max-width: 2% !important;
}

.barGraph {
  width: 100%;
  box-shadow: 0px 1px 3px 0px #1018281a;
  background: #fff;
  border-radius: 12px;
  margin: 30px 0;
  padding: 26px 23px 10px 16px;
}

.barHeading h4 {
  color: #475467;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter";
  padding-bottom: 1px;
}

.barHeading h6 {
  color: #101828;
  font-size: 30px;
  font-weight: 600;
  font-family: "Inter";
}

.partnerBadge {
  color: #067726;
  font-size: 14px;
  display: flex;
  width: 102px;
  height: 24px;
  background: #f3fdec;
  justify-content: center;
  border-radius: 100px;
  margin-left: 17px;
  font-weight: 500;
}

.spartnerBadge {
  color: #155eef;
  font-size: 14px;
  display: flex;
  width: 102px;
  height: 24px;
  background: #eef3fa;
  justify-content: center;
  border-radius: 100px;
  margin-left: 17px;
  font-weight: 500;
}

.employeeTypeBadge {
  background: rgb(242, 242, 243);
  padding: 0 0.5rem;
  border: 1.2px solid rgb(230, 230, 232);
  border-radius: 0.5rem;
  color: rgb(71, 70, 74);
  font-size: 14px;
  font-weight: 500;
  margin-left: 7px;
}

.innerLabel {
  padding: 0 !important;
  width: 19% !important;
}

.cardLabels {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}

.ant-picker-input input::placeholder {
  color: #667085 !important;
}

.ant-picker-input .ant-picker-suffix {
  margin: 0 8px 0 0px !important;
}

.ant-picker-input {
  flex-flow: row-reverse !important;
}

.ant-picker {
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-family: "Inter";
  color: #667085 !important;
  border: 1px solid #d0d5dd !important;
}

.barHeading {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.month {
  text-align: center;
  font-size: 12px;
  font-family: "Inter";
  color: #475467;
  font-weight: 500;
  position: relative;
  bottom: 14px;
}

.apexcharts-legend-series span {
  border-radius: 100px !important;
  font-family: "Inter";
}

.rowSection {
  width: 558px !important;
  margin: 0 auto;
  padding: 36px 0;
}

.viewModal .ant-modal-footer {
  display: none !important;
}

.rowSection input {
  height: 44px !important;
  border: 1px solid #d0d5dd !important;
  background: #f9fafb;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  margin-bottom: 18px;
  font-family: "Inter";
}

.deletePoint {
  position: relative;
  top: 11px !important;
}

.rowInput .ant-input.ant-input-disabled {
  background: #f9fafb !important;
  color: #475467 !important;
}
.ant-drawer span.ant-input-affix-wrapper input::placeholder {
  color: #667085 !important;
}
.croppedImage {
  width: 64px !important;
  min-width: 64px !important;
  border-radius: 100px !important;
}
.croppedImage img {
  width: 64px !important;
  min-width: 64px !important;
  border-radius: 100px !important;
}
.p_input {
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  color: #101828;
  font-size: 16px !important;
}
.passwordsForm .ant-input-affix-wrapper {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px;
}
.tabelSetting table th {
  background: #f9fafb !important;
  padding: 11px 16px !important;
  color: #475467 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}
.crossButton {
  position: absolute;
  right: 16px;
  top: 17px;
  z-index: 999;
}
.editPopup {
  width: 459px !important;
}
.removePopup {
  box-shadow: 0px 8px 8px -4px #10182808;
  box-shadow: 0px 20px 24px -4px #10182814 !important;
  border-radius: 12px !important;
}
.selectField .ant-select-selection-item {
  font-size: 16px !important;
  color: #101828 !important;
  font-weight: 500 !important;
}
.selectField .ant-select-selector {
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px !important;
}
.heads {
  height: 73px !important;
}
.addModels {
  width: 129px !important;
  height: 36px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  position: relative;
  right: 16px;
  top: 1px;
}
.defautBtn {
  font-size: 12px !important;
  color: #027a48 !important;
  font-weight: 500 !important;
  font-family: "Inter" !important;
}
.tabelSetting table td {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #475467 !important;
}
.tabelSetting {
  padding: 0 0 20px !important;
  box-shadow: 0px 1px 3px 0px #1018281a !important;
  border: 1px solid #eaecf0 !important;
  border-radius: 12px;
}
.passwordsForm .ant-input-affix-wrapper input {
  color: #101828;
  font-size: 16px;
}
.tabelSetting {
  padding: 0 0 20px !important;
}
.passwordsForm .ant-input-affix-wrapper input::placeholder {
  color: #667085 !important;
}
.ant-drawer span.ant-input-affix-wrapper input {
  color: #101828 !important;
}
.ant-input.ant-input-disabled {
  background: #f9fafb !important;
  margin-top: 0 !important;
  color: #475467 !important;
}
.searchTegs input {
  color: #101828;
}
.searchTegs input::placeholder {
  color: #667085;
}
.rowBox input {
  font-family: "Inter";
  height: 44px !important;
  border: 1px solid #d0d5dd !important;
  background: #f9fafb;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  margin-bottom: 12px;
  margin-top: 0 !important;
}

.rowBox .ant-select {
  margin-top: 0 !important;
}

.rowBox .ant-select .ant-select-selector {
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1px solid #d0d5dd !important;
}

.rowBox .ant-select-arrow {
  top: 27px !important;
}

.rowBox span {
  color: #344054;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  display: block;
  line-height: 20px;
  padding-bottom: 6px;
  padding-top: 0 !important;
}

.rowBox label {
  color: #344054;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  display: block;
  line-height: 20px;
  padding-bottom: 6px;
}

.searchTag {
  display: flex;
}

.bodyModal {
  padding: 20px !important;
}

.bodyModal input {
  height: 44px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  background: #f9fafb;
  width: 96%;
  margin-bottom: 16px;
  color: #475467;
  font-weight: 400;
  font-family: "Inter";
}

.bodyModal input::placeholder {
  color: #475467;
  font-weight: 400;
  font-family: "Inter";
}

/* .bodyModal input {
  color:
    #475467;
  font-weight: 400;
  font-family: 'Inter';
} */
.headerDesktop {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eff1f4;
}

.bodyModal label {
  display: block;
  line-height: 20px;
  font-size: 14px;
  color: #344054;
  font-weight: 500;
  font-family: "Inter";
  padding-bottom: 6px;
}

.headerDesktop img {
  margin-right: 14px;
}

.tableCollapse thead tr th:nth-child(1) {
  width: 34px !important;
  max-width: 28px !important;
  min-width: 27px !important;
}

.bg_1 {
  width: 30px !important;
  max-width: 33px;
}

.bg_2 {
  width: 88px !important;
}

.bg_3 {
  width: 214px !important;
}

.bg_4 {
  width: 148px !important;
}

.bg_5 {
  width: 142px !important;
  color: #475467 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.bg_6 {
  max-width: 184px;
  overflow: hidden;
  width: 180px !important;
  color: #475467 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.bg_7 {
  width: 182px !important;
  color: #475467 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.bg_8 {
  width: 226px !important;
}

.bg_9 {
  width: 82px !important;
}

.profileSection {
  display: flex;
  white-space: nowrap;
  align-items: center;
}

.innerTable {
  background-color: #f9f9f9 !important;
}

.tableCollapse {
  border-radius: 12px !important;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a !important;
  border: 1px solid #eaecf0;
}

.profileSection img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  object-fit: cover;
  border-radius: 50px;
}
.reviewTable.news {
  border: 0 !important;
}
.bg_8 span {
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: #475467;
  margin-right: 22px;
}

img.rotate {
  transform: rotate(180deg);
}

.sm_8 span {
  font-size: 16px !important;
  font-family: "Inter" !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: #475467;
  margin-right: 22px;
}

.sm_1 {
  width: 39px !important;
  max-width: 38px;
}

.sm_2 {
  width: 103px !important;
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.blue_Bg {
  height: 22px;
  background: #eef3fa;
  display: flex;
  justify-content: center;
  border-radius: 100px;
  font-size: 12px;
  color: #155eef;
  align-items: center;
  font-family: "Inter";
  font-weight: 400;
  width: fit-content;
  padding: 0 12px;
}

.theadTable .MuiTableCell-root .MuiButtonBase-root.MuiCheckbox-root svg {
  background-image: url(../src//assets/images/checkbox.png);
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

.removeBtn {
  text-align: end;
  padding: 24px 20px;
}

.removeBtn button {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
  width: 94px;
  height: 44px;
  color: #344054;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter";
  border-radius: 8px;
  margin-left: 12px;
}

.removeBtn button:hover {
  background-color: #d92d20 !important;
  color: #fff !important;
  border: 1px solid #d92d20 !important;
}

body .modalViewPopup.newRemove button.ant-modal-close {
  top: 27px !important;
}

.modalViewPopup.newRemove .headerView {
  border: 0 !important;
}

.headerView h5 {
  font-family: "Inter";
  color: #101828;
  font-size: 18px;
  font-weight: 600;
}

.pText {
  font-family: "Inter";
  color: #475467;
  font-size: 14px;
  font-weight: 400;
}

.theadTable .MuiTableCell-root .MuiButtonBase-root.MuiCheckbox-root svg path {
  opacity: 0;
}

.bigTableBody .MuiTableCell-root .MuiButtonBase-root.MuiCheckbox-root svg path {
  opacity: 0;
}

.Mui-checked svg path {
  opacity: 1 !important;
}

.Mui-checked svg {
  background-image: none !important;
}

.theadTable tr th {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.green_Bg {
  width: fit-content;
  height: 22px;
  background: #f3fdec;
  display: flex;
  justify-content: center;
  border-radius: 100px;
  font-size: 12px;
  color: #067726;
  align-items: center;
  font-weight: 400;
  padding: 0 12px;
}

.bigTableBody tr td:nth-child(2) {
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.bigTableBody tr td:nth-child(3) {
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}
body .innerBodys {
  border: 0 !important;
}
.innerBodys .innerSection .sm_1 {
  width: 48px !important;
  max-width: 48px !important;
}
.innerBodys .innerSection .sm_3 {
  width: 314px !important;
  max-width: 314px !important;
}
.innerBodys .innerSection .sm_2 {
  width: 129px !important;
  max-width: 129px !important;
}
.tableCollapse tbody td {
  border-bottom: 1px solid #eaecf0;
}

.tableCollapse table thead tr th {
  color: #475467;
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter";
  line-height: 18px;
  background: #f9fafb;
  padding-top: 12px;
  padding-bottom: 12px;
}

.modalViewPopup .ant-modal-close {
  /* top: 47px !important; */
}

.modalViewPopup {
  width: 593px !important;
  border-radius: 12px !important;
}

.headerView {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eff1f4;
}

.headerView img {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  margin-right: 16px;
}

.headerView h6 {
  color: #667085 !important;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  line-height: 20px;
  padding: 6px 0;
}

.modalViewPopup .ant-modal-footer {
  display: none !important;
}

.gridBox label {
  font-size: 14px;
  display: block;
  font-family: "Inter";
  padding-bottom: 6px;
  font-weight: 500;
}

.gridBox input::placeholder {
  font-family: "Inter";
  color: #667085;
}

.dropBoxSelect .MuiFormControl-root fieldset {
  border-color: #d0d5dd !important;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
}

.dropBoxSelect .MuiFormControl-root .MuiInputBase-root input {
  height: auto !important;
  background: transparent !important;
  margin: 0 !important;
  border: 0 !important;
}

.modalBtn {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 20px;
  border-top: 1px solid #eaecf0;
}

.modalBtn button:hover {
  background-color: #155eef !important;
  color: #fff !important;
  border: 1px solid #155eef !important;
}

.modalBtn button {
  width: 100% !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  height: 44px;
  border: 1px solid #d0d5dd;
  color: #344054;
  font-size: 16px;
  font-weight: 600;
  margin: 0 4px;
}

.dropBoxSelect {
  height: 44px !important;
  margin-bottom: 9px !important;
}

.dropBoxSelect .MuiFormControl-root {
  height: 44px !important;
}

.dropBoxSelect .MuiFormControl-root .MuiInputBase-root {
  height: 44px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.gridBox input {
  height: 44px;
  background: #f9fafb;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px;
  margin-bottom: 12px;
  font-family: "Inter";
  color: #667085;
}

.gridBox {
  padding: 20px !important;
}

.headerView h3 {
  color: #101828 !important;
  font-size: 24px;
  font-weight: 500;
  font-family: "Inter";
  line-height: 20px;
  padding-top: 11px;
}

.modalViewPopup .ant-modal-content {
  padding: 0 !important;
}

body .modalViewPopup.partner .ant-modal-close {
  top: 12px !important;
}

.multiSelect .ant-space-item {
  font-size: 14px;
  font-weight: 500;
  color: #344054 !important;
  font-family: "Inter";
}

.multiSelect .ant-select-selection-search-input {
  border: 0 !important;
  outline: 0 !important;
}

.multiSelect .ant-image-img {
  position: relative;
  top: 3px;
  left: 2px;
}

.ant-picker-cell.ant-picker-cell-disabled::before {
  display: none !important;
}

td.ant-picker-cell.ant-picker-cell-disabled {
  font-size: 14px;
  color: #667085;
  opacity: 0.3;
  font-weight: 400;
  font-family: "Inter";
}

.searchTegs input::placeholder {
  color: #667085;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
}
.maxWidth {
  width: 6px !important;
  max-width: 5px !important;
  padding: 0 !important;
}
.apexcharts-legend-series span {
  border-radius: 100px !important;
  font-family: "Inter" !important;
  color: #475467 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.paginationBtn button {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
  color: #344054 !important;
  font-weight: 600 !important;
  font-family: "Inter";
}

.companiesModal tbody tr td:nth-child(1) {
  font-weight: 500 !important;
  color: #101828 !important;
}

.viewss {
  font-family: "Inter";
  color: #475467 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding-right: 6px;
}

.fontss {
  text-align: center;
  font-weight: 500;
  font-size: 12px !important;
}

.companiesModal tbody tr td {
  height: 72px !important;
  color: #475467;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Inter" !important;
}

.companiesModal thead tr th {
  background: #f9fafb !important;
  padding-top: 11px !important;
  padding-bottom: 10px !important;
  color: #475467 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.ant-picker-panel-layout {
  justify-content: center;
}

.ant-picker-panel-layout {
  justify-content: center;
  padding: 15px 15px 8px !important;
}

.ant-picker-year-panel {
  width: 320px !important;
}

.ant-picker-panel-container.ant-picker-year-panel-container {
  box-shadow: 0px 8px 8px -4px #10182808;
  box-shadow: 0px 20px 24px -4px #10182814;
  border: 1px solid #f2f4f7;
  width: auto;
  border-radius: 8px;
}

td.ant-picker-cell.ant-picker-cell-hover.ant-picker-cell-selected.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  background: #fff !important;
  color: #155eef !important;
  font-weight: 400;
  font-size: 14px !important;
}

.ant-picker-year-panel .ant-picker-header {
  border: 0 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Inter" !important;
}

.dashBoardCard .grid {
  width: 100%;
  height: 114px;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a !important;
  border-radius: 12px !important;
  border: 1px solid #eaecf0;
  padding: 22px !important;
}

.lineChart .apexcharts-legend-marker {
  width: 8px !important;
  height: 8px !important;
  position: relative;
  top: 0px !important;
  margin-right: 8px;
}

.dashBoardCard .grid .title {
  font-size: 30px !important;
  color: #101828 !important;
  font-family: "Inter";
  font-weight: 600;
}

.dashBoardCard .grid .head {
  color: #475467 !important;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
}

.activeRound {
  font-weight: 500;
  font-family: "Inter";
  color: #344054 !important;
  font-size: 12px !important;
  background: #f2f4f7 !important;
  width: 30px !important;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pathActive {
  height: 40px;
  background: #eff4ff;
  border-radius: 6px !important;
  font-size: 16px;
  font-weight: 600;
}

.bottomText {
  position: absolute;
  width: 100%;
  bottom: 36px;
}

.multiSelect .anticon.anticon-down.ant-select-suffix svg {
  display: none !important;
}

.multiSelect .anticon.anticon-down.ant-select-suffix {
  background-image: url(../src/assets/images/Icon.png);
  border: 0 !important;
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-position: center;
}

.antCheck .ant-checkbox-inner::after {
  background-image: url(../src/assets/images/fill.png);
  border: 0 !important;
  width: 20px;
  height: 20px;
  background-size: fill;
  background-repeat: no-repeat;
  transform: rotate(360deg);
  background-position: top;
}

.antCheck .ant-checkbox-inner {
  background-image: url(../src/assets/images/check.png);
  width: 20px;
  height: 20px;
  border: 0 !important;
}

.multiSelect .ant-image-mask {
  display: none !important;
}

.multiSelect .anticon.anticon-eye {
  display: none !important;
}

.multiSelect .ant-select-selector {
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  padding: 7px;
}

.multiSelect .ant-tag {
  padding-left: 2px;
  display: flex !important;
  align-items: center;
  background: transparent;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
}

.bg_blue {
  position: absolute;
  right: 17px;
  width: 110px;
  height: 24px;
  background: #eef3fa;
  text-align: center;
  border-radius: 16px;
  color: #155eef;
  font-size: 14px;
  font-weight: 500;
}

span.bg_green {
  position: absolute;
  right: 17px;
  width: 102px;
  height: 24px;
  background: #f3fdec;
  text-align: center;
  border-radius: 16px;
  color: #067726;
  font-size: 14px;
  font-weight: 500;
}

.superPartner h3 {
  color: #101828;
  font-family: "Inter";
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
}

.superPartner h6 {
  color: #101828;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.innerSection {
  background: #f9f9f9 !important;
}

.superPartner p {
  color: #475467;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sm_3 {
  width: 250px !important;
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  position: relative;
  right: 0px;
}

.sm_4 {
  width: 173px !important;
  position: relative;
  right: 0px;
}

.sm_5 {
  width: 166px !important;
  color: #475467 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.sm_6 {
  width: 160px !important;
  color: #475467 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.sm_7 {
  width: 162px !important;
  color: #475467;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

/* .sm_3 {
  width: 88px !important;
}
.sm_4 {
  width: 88px !important;
}
.sm_5 {
  width: 88px !important;
}
.sm_6 {
  width: 88px !important;
}
.sm_7 {
  width: 88px !important;
}
.sm_8 {
  width: 88px !important;
}
.sm_9 {
  width: 88px !important;
} */
.viewPopup .ant-modal-content {
  padding: 0 !important;
}

.viewPopup .ant-modal-footer {
  display: none !important;
}

.viewPopup .ant-modal-close {
  top: 50px !important;
}

.footerModal button:hover {
  background-color: #000 !important;
  color: #fff !important;
  border-color: #000 !important;
}

.footerModal button {
  border: 1px solid #d0d5dd;
  color: #344054;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter";
  height: 44px;
  width: 100%;
  box-shadow: 0px 1px 2px 0px #1018280d;
  margin: 0 4px;
}

.footerModal {
  display: flex;
  justify-content: space-between;
  padding: 28px 26px 24px;
  border-top: 1px solid #eff1f4;
}

.headerDesktop h3 {
  font-size: 24px;
  color: #101828;
  font-weight: 500;
  font-family: "Inter";
}

.headerDesktop p {
  font-size: 16px;
  color: #101828;
  opacity: 0.4;
  font-weight: 400;
  font-family: "Inter";
}

.viewPopup {
  width: 594px !important;
}

.export button {
  height: 40px;
  background-color: #000 !important;
  border: 1px solid #000 !important;
  color: #fff !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px !important;
  font-size: 16px;
  font-weight: 600;
}

.searchTag button {
  align-items: center;
  display: flex;
  height: 40px;
  background: #eff4ff !important;
  border: 0 !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  color: #667085 !important;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  margin-right: 11px;
}

.searchTag button img {
  margin-right: 5px;
}

.userText {
  display: flex;
  align-items: center;
}

.userText img {
  margin-right: 8px;
}

.rowInput .ant-select-arrow {
  top: 25px !important;
}

.revenueInput img {
  position: absolute;
  right: 11px;
  top: 39px;
}

.rowInput .ant-select-selector {
  height: 44px !important;
  border: 1px solid #d0d5dd !important;
  background: #f9fafb !important;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  margin-bottom: 18px;
  font-family: "Inter";
}

span {
  font-family: "Inter" !important;
}

.rowSection span {
  color: #344054;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  display: block;
  line-height: 20px;
  padding-bottom: 6px;
}

.rowSection label {
  color: #344054;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  display: block;
  line-height: 20px;
  padding-bottom: 6px;
}

.viewModal .ant-modal-content {
  padding: 0 !important;
}

.viewHeader h3 {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 20px;
}

.viewHeader {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eff1f4;
  padding: 24px;
  justify-content: space-between;
}

.gridSide .MuiGrid-grid-xs-3::-webkit-scrollbar {
  width: 3px !important;
  background-color: transparent;
}

.gridSide .MuiGrid-grid-xs-3::-webkit-scrollbar-thumb {
  padding-top: 10px;
  background-color: silver;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for the scrollbar thumb */
  max-height: 50px;
  /* Set the height of the scrollbar thumb */
}

.input.datepicker input {
  padding: 0 10px !important;
  border: 0 !important;
}

.input.datepicker fieldset {
  border: 1px solid #000 !important;
  border-radius: 8px !important;
}

.input.datepicker .MuiStack-root {
  padding: 0 !important;
  height: 44px !important;
  /* border: 1px solid #000 !important; */
  border-radius: 8px !important;
}

.input.datepicker .MuiInputBase-root {
  height: 43px !important;
  border: 0 !important;
}

.gridSide .MuiGrid-grid-xs-3::-webkit-scrollbar-track {
  background-color: transparent;
  /* Background color of the scrollbar track */
}

.gridSide .MuiGrid-grid-xs-3 {
  height: 450px;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.container.head {
  margin-top: 100px;
}

.btnDate svg {
  margin-right: 5px;
}

.filterOption button img {
  margin-right: 8px;
}

.btnDate {
  display: flex;
  align-items: center;
  font-family: "Inter";
  border: 1px solid #d0d5dd !important;
  color: #344054 !important;
  font-size: 14px;
  font-weight: 600;
}

.filterBox {
  display: flex;
  margin: 20px 0;
  position: relative;
}

.tagBtn {
  font-family: "Inter";
  align-items: center;
  display: flex;
  box-shadow: 0px 1px 2px 0px #1018280d;
  background: #eff4ff !important;
  border-radius: 8px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  width: auto;
  border: 0 !important;
  color: #667085 !important;
}

.tagBtn img {
  margin-right: 6px;
}

.gridInput .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 3px !important;
}

.gridInput fieldset {
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
}

.gridInput .MuiAutocomplete-endAdornment button {
  margin: 0 !important;
}

.filterBox button {
  margin-right: 20px;
  font-family: "Inter";
}

.filterSection {
  position: absolute;
  background: #fff;
  box-shadow: 0px 8px 32px 0px #0000000f;
  width: 416px;
  border-radius: 16px;
  border: 1px solid #dcdbdd;
  margin: 14px 0;
  z-index: 9;
}

.filterSection h1 {
  font-size: 24px;
  font-family: "Inter";
  color: #101828;
  font-weight: 600;
  padding: 28px 31px 20px;
}

.filterMenu h5 {
  font-size: 20px;
  font-family: "Inter, sans-serif";
  color: #202020;
  font-weight: 500;
  padding-left: 31px;
  padding-bottom: 12px;
}

.gridInput {
  position: relative;
  margin-bottom: 18px;
}

.gridInput label {
  font-family: "Inter";
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 5px;
  display: block;
}

/* .gridInput svg {
  margin-right: 10px;
} */

.valueInput {
  height: 44px !important;
  border: 1px solid #d0d5dd !important;
  font-family: "Inter";
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px !important;
  font-size: 16px;
  color: #101828;
}

.valueInput::placeholder {
  color: #667085 !important;
  font-family: "Inter" !important;
  font-size: 16px;
  opacity: 1 !important;
}

.valueInput .MuiInputBase-root {
  height: 48px !important;
}

.valueInput input {
  height: 48px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 30px;
}

.valueInput fieldset {
  border: 1px solid #dcdbdd !important;
  border-radius: 12px !important;
}

.inputSvg {
  position: absolute;
  right: 6px;
  top: 40px;
}

.selectInput {
  width: 100%;
}

.customDropdown {
  position: relative;
}

.innerBox {
  box-shadow: 0px 8px 32px 0px #0000000f;
  border: 1px solid #dcdbdd;
  border-radius: 12px;
  position: absolute;
  width: 100%;
  background: #fff;
  margin: 12px 0;
  z-index: 9;
}

.innerBox ul {
  padding: 0;
}

.innerBox ul li {
  list-style: none;
}

.listMenu {
  display: flex;
  align-items: center;
  font-size: 14px !important;
  color: #47464a !important;
  font-family: "Inter, sans-serif" !important;
  padding-left: 17px !important;
  cursor: pointer;
}

.listMenu label {
  margin-right: 6px !important;
  position: relative;
  top: 4px;
  padding-left: 7px 18px;
}

.listMenu .MuiButtonBase-root {
  padding: 0 !important;
}

.listMenu svg {
  width: 17px !important;
  height: 17px !important;
}

.selectInput input {
  padding: 13px !important;
}

.selectInput fieldset {
  border: 1px solid #dcdbdd !important;
  border-radius: 12px !important;
}

.downArrow {
  position: absolute;
  right: 4px;
  top: 49px;
}

.rdrDay {
  margin: 0 !important;
  /* width: 47px !important;
  height: 47px !important; */
}

.calendarCancel {
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  color: #344054;
  font-family: "Inter";
  font-weight: 600;
}

.ant-select-arrow {
  background-repeat: no-repeat;
  width: 12px !important;
  height: 7px !important;
  background-image: url(../src/assets/images/preview.png);
}

.rounded-\[16px\].bg-\[\#F2F4F7\].px-2 {
  font-family: "Inter";
}

.custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  ul {
  padding-left: 0 !important;
  border-right: 1px solid #eaecf0 !important;
}
button.ant-picker-header-next-btn {
  visibility: inherit !important;
}
button.ant-picker-header-prev-btn {
  visibility: inherit !important;
}
/* .custom-calendar-class .ant-picker-header-prev-btn {
  display: none !important;
}

.custom-calendar-class .ant-picker-header-next-btn {
  display: none !important;
} */

.custom-calendar-class .ant-picker-header {
  border-bottom: 0 !important;
}

.custom-calendar-class .ant-picker-content thead tr th {
  color: #344054 !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.custom-calendar-class
  td.ant-picker-cell.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  color: #344054;
  font-family: "Inter" !important;
  font-size: 14px !important;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400 !important;
}
.custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background-color: #155eef !important;
  border-radius: 100px !important;
}
.custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(
    .ant-picker-cell-disabled
  ):before {
  background-color: #f9fafb !important;
  height: 40px;
}
.custom-calendar-class .ant-picker-content td {
  padding: 0 !important;
}
.custom-calendar-class .ant-picker-header-super-next-btn {
  visibility: inherit !important;
}
.custom-calendar-class .ant-picker-header-super-prev-btn {
  visibility: inherit !important;
}
.custom-calendar-class .ant-input::placeholder {
  color: #101828;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.custom-calendar-class .ant-picker-footer-extra .ant-btn-default {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
  height: 40px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  color: #344054 !important;
  font-weight: 600;
}
.custom-calendar-class .ant-picker-footer-extra {
  position: relative !important;
}
.custom-calendar-class .ant-picker-footer-extra .ant-btn-primary {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
  height: 40px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 600;
  background-color: #155eef !important;
}
.custom-calendar-class .ant-input {
  border: 1px solid #d0d5dd !important;
  color: #101828;
  font-size: 16px !important;
  font-weight: 400 !important;
  height: 44px !important;
  font-family: "Inter";
  width: 136px;
  margin-right: 28px;
  border-radius: 8px;
}
.custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  content: "." !important;
  border: 0 !important;
  color: #155eef;
  position: absolute;
  top: 10px;
  font-size: 40px !important;
}

.custom-calendar-class .ant-picker-footer-extra::before {
  content: "";
  background-image: url(./assets/images/text.png);
  width: 12px !important;
  position: absolute !important;
  height: 2px;
  bottom: 38px;
  left: 156px;
}
.custom-calendar-class .ant-picker-footer-extra .w-full.py-4 {
  display: flex !important;
  align-items: baseline !important;
  padding: 10px 0 0 !important;
}
.custom-calendar-class .ant-picker-panels {
  padding-top: 12px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.custom-calendar-class .ant-picker-panel-layout {
  padding: 0 14px !important;
}
.custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  ):before {
  background-color: #f9fafb !important;
}
.custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  ):before {
  background-color: #f9fafb !important;
}
.custom-calendar-class
  .ant-picker-cell.ant-picker-cell-range-start.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  border-radius: 100px !important;
  background-color: #155eef !important;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-calendar-class td.ant-picker-cell .ant-picker-cell-inner {
  color: #667085;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.custom-calendar-class .ant-picker-year-btn {
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Inter" !important;
}

.custom-calendar-class .ant-picker-month-btn {
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Inter" !important;
}

.custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  li {
  color: #344054 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  height: 40px !important;
  display: flex;
  align-items: center;
}

.custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  .ant-picker-presets {
  width: 170px !important;
}

span.ant-picker-super-prev-icon::before {
  display: none !important;
}

.ant-picker-super-prev-icon::after {
  display: none !important;
}

.custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container {
  box-shadow: 0px 20px 24px -4px #10182814;
  border: 1px solid #f2f4f7 !important;
  border-radius: 8px !important;
}

.ant-picker-super-prev-icon {
  background-image: url(../src/assets/images/lefts.png);
  background-repeat: no-repeat;
  width: 12px !important;
  background-size: contain;
  height: 12px !important;
  transform: rotate(360deg) !important;
}

.ant-picker-super-next-icon::before {
  display: none !important;
}

.ant-picker-super-next-icon::after {
  display: none !important;
}

.ant-picker-super-next-icon {
  background-image: url(../src/assets/images/rights.png);
  background-repeat: no-repeat;
  width: 12px !important;
  background-size: contain;
  height: 12px !important;
  transform: rotate(360deg) !important;
}

.dropBoxSelect input {
  color: #101828;
  font-family: "Inter" !important;
  font-size: 16px;
  font-weight: 500;
}

.dropBoxSelect svg {
  opacity: 0 !important;
}

.dropBoxSelect button {
  background-image: url(../src/assets/images/preview.png);
  width: 13px !important;
  height: 7px !important;
  background-repeat: no-repeat !important;
}

.viewModal.admins .ant-select-selection-item {
  color: #101828;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
}

button.rdrDay.rdrDayActive .rdrDayNumber {
  top: 13px !important;
}

.ant-select-arrow svg {
  display: none !important;
}

.rdrEndEdge + .rdrDayNumber {
  top: 13px !important;
}

.calendarApply {
  background: #155eef;
  color: #fff;
  font-family: "Inter" !important;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  border: 1px solid #155eef;
  border-radius: 6px !important;
}

.rdrStartEdge {
  background: #155eef !important;
  border-radius: 100px !important;
  height: 44px !important;
  display: flex !important;
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
}

.rdrEndEdge {
  background: #155eef !important;
  border-radius: 100px !important;
  height: 47px !important;
  display: flex !important;
  width: 47px !important;
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
}

.dateBox {
  position: absolute;
  background: #fff;
  z-index: 9;
  padding: 14px 14px 66px;
  box-shadow: 0px 8px 32px 0px #0000000f;
  border-radius: 16px;
  border: 1px solid #dcdbdd;
  margin: 15px 0;
}

.buttonLastYear.rdrStaticRangeLabel:hover {
  background: #eff2f7;
}

.buttonLastYear.rdrStaticRangeLabel {
  cursor: pointer;
}

.buttonThisYear.rdrStaticRangeLabel {
  cursor: pointer;
}

.buttonThisYear.rdrStaticRangeLabel:hover {
  background: #eff2f7;
}

.rdrDayToday .rdrEndEdge {
  background: transparent !important;
}

.rdrDayToday {
  background: #f2f4f7 !important;
  color: #000 !important;
  border-radius: 34px !important;
  height: 44px !important;
}

.rdrDayToday span {
  color: #000 !important;
}

.rdrInRange {
  background: #f9fafb !important;
  width: 47px !important;
  height: 47px !important;
  top: 0 !important;
  color: #000 !important;
}

.flex-row-reverse {
  flex-direction: row-reverse;
  position: absolute;
  right: 0;
  border: 1px solid #eaecf0;
  width: 74%;
  height: 67px;
  display: flex;
  align-items: center;
}

.rdrDateRangePickerWrapper {
  position: relative;
}

.rdrDayNumber span {
  color: #344054 !important;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter" !important;
}

.rdrDay .rdrStartEdge + .rdrDayNumber span {
  color: #fff !important;
}

.rdrDay .rdrEndEdge + .rdrDayNumber span {
  color: #fff !important;
}

.rdrDay.rdrDayToday .rdrEndEdge + .rdrDayNumber span {
  color: #000 !important;
}

.rdrDayToday .rdrDayNumber span:after {
  width: 4px !important;
  height: 4px !important;
  border-radius: 3px !important;
  background: #155eef !important;
}

.selectBox.modal button {
  margin-right: 0 !important;
}

.selectBox.modal button svg {
  margin-right: 0 !important;
}

.filterBtns {
  text-align: end;
  position: relative;
  bottom: 4px;
}

.filterBtns button {
  box-shadow: 0px 1px 2px 0px #1018280d;
  height: 40px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #344054;
  font-size: 14px !important;
  text-transform: capitalize;
  font-family: Inter, sans-serif;
}

.totalEvents {
  color: #101828;
  font-size: 24px;
  font-family: "Inter";
  font-weight: 500;
}

.seperator {
  display: none;
}

.filterBtns button:hover {
  border-color: #155eef !important;
  background: #155eef !important;
  color: #fff !important;
}

.rdrDayStartPreview {
  border-top-width: 0px !important;
  border-left-width: 0px !important;
  border-bottom-width: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  left: 0px !important;
}

.rdrDayEndPreview {
  border-top-width: 0px !important;
  border-left-width: 0px !important;
  border-bottom-width: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  left: 0px !important;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  color: #fff !important;
}

.cancelBtn {
  width: 48%;
  border: 1px solid #d0d5dd;
  text-transform: capitalize;
  font-size: 16px;
  color: #344054;
  height: 44px;
  border-radius: 8px;
  font-weight: 600;
  font-family: "Inter" !important;
  margin: 0 !important;
}

.cancelBtn:hover {
  background: #155eef !important;
  color: #fff !important;
  border-color: #155eef !important;
}

.rdrDateDisplayWrapper {
  height: 66px !important;
  overflow: hidden;
}

.rdrInputRanges {
  display: none !important;
}

.rdrDay.rdrDayStartOfMonth .rdrStartEdge {
  color: red !important;
  background: transparent !important;
}

.rdrDay.rdrDayPassive .rdrEndEdge + .rdrInRange + .rdrDayNumber span {
  color: #333 !important;
}

.rdrDay.rdrDayStartOfWeek.rdrDayStartOfMonth
  .rdrStartEdge
  + .rdrDayNumber
  span {
  color: #333 !important;
}

.rdrDay.rdrDayEndOfMonth .rdrEndEdge + .rdrInRange + .rdrDayNumber span {
  color: #333 !important;
}

.rdrDay.rdrDayStartOfWeek.rdrDayStartOfMonth
  .rdrStartEdge
  + .rdrInRange
  + .rdrDayInPreview {
  border: 0 !important;
}

.rdrDay.rdrDayStartOfMonth .rdrStartEdge + .rdrInRange + .rdrInRange {
  background: #000 !important;
}

.rdrDay.rdrDayStartOfMonth .rdrStartEdge + .rdrInRange {
  background: #000 !important;
  border-radius: 100px;
  left: -1px !important;
}

.rdrDay.rdrDayWeekend.rdrDayEndOfWeek.rdrDayEndOfMonth
  .rdrEndEdge
  + .rdrInRange
  + .rdrDayNumber
  span {
  color: #333 !important;
}

.rdrDay.rdrDayStartOfMonth
  .rdrStartEdge
  + .rdrInRange
  + span
  + .rdrDayNumber
  span {
  color: #fff !important;
}

.rdrDay.rdrDayToday.rdrDayStartOfWeek .rdrDayInPreview {
  border: 0 !important;
}

.rdrDay .rdrStartEdge + .rdrDayInPreview {
  border: 0 !important;
}

.rdrDay .rdrEndEdge + .rdrDayInPreview {
  border: 0 !important;
}

.rdrDayHovered {
  position: relative !important;
}

.rdrDayStartPreview.rdrDayEndPreview {
  display: none !important;
}

.rdrDay.rdrDayWeekend.rdrDayEndOfWeek.rdrDayEndOfMonth
  .rdrStartEdge
  + .rdrDayEndPreview {
  border: 0 !important;
}

.rdrDay.rdrDayWeekend.rdrDayEndOfWeek .rdrStartEdge + .rdrDayStartPreview {
  border: 0 !important;
}

.rdrDay.rdrDayToday .rdrDayInPreview {
  border: 0 !important;
}

.rdrDay.rdrDayToday .rdrStartEdge.rdrEndEdge + span + .rdrDayInPreview {
  border: 0 !important;
}

.rdrDay.rdrDayToday .rdrStartEdge.rdrEndEdge + span + .rdrDayNumber span {
  color: #000 !important;
}

.rdrDay.rdrDayToday.rdrDayActive
  .rdrStartEdge.rdrEndEdge
  + span
  + .rdrDayInPreview
  + .rdrDayNumber
  span {
  border: 0 !important;
}

.rdrDay.rdrDayToday.rdrDayActive
  .rdrStartEdge.rdrEndEdge
  + span
  + .rdrDayNumber
  span {
  color: #000 !important;
}

.rdrDay.rdrDayToday.rdrDayStartOfWeek
  .rdrStartEdge.rdrEndEdge
  + .rdrDayInPreview
  + .rdrDayNumber
  span {
  color: #000 !important;
}

.rdrDay.rdrDayToday.rdrDayStartOfWeek
  .rdrStartEdge.rdrEndEdge
  + span
  + .rdrDayNumber
  span {
  color: #000 !important;
}

.rdrDay.rdrDayToday.rdrDayStartOfWeek
  .rdrStartEdge.rdrEndEdge
  + .rdrDayInPreview {
  border: 0 !important;
}

.rdrDay .rdrStartEdge.rdrEndEdge + .rdrDayStartPreview + .rdrDayNumber span {
  color: #fff !important;
}

.rdrDay .rdrStartEdge.rdrEndEdge + .rdrDayEndPreview + .rdrDayNumber span {
  color: #fff !important;
}

.rdrDay .rdrStartEdge.rdrEndEdge + .rdrDayInPreview + .rdrDayNumber span {
  color: #fff !important;
}

.rdrDay .rdrStartEdge + span + .rdrDayNumber span {
  color: #fff !important;
}

.rdrDay .rdrEndEdge + span + .rdrDayNumber span {
  color: #fff !important;
}

.rdrDay .rdrStartEdge.rdrEndEdge + span + .rdrDayNumber span {
  color: #fff !important;
}

.past-selected-date {
  background-color: red;
  /* Change this to your desired color */
}

/* .day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: 1px solid #ccc;
} */
.selected-date {
  background-color: red;
  /* Change this to your desired color */
}

.past-selected-date {
  background-color: red;
  /* Change this to your desired color */
}

.tabs {
  display: flex;
  border-bottom: 1px solid #eaecf0;
}

.tabs span {
  color: #667085;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter";
  margin-right: 25px;
  cursor: pointer;
  padding-bottom: 12px;
}

.userName h6 {
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  padding-bottom: 5px;
}

.userName h5 {
  color: #101828;
  font-size: 16px;

  font-weight: 600;
  font-family: "Inter";
}

.leftSide {
  display: flex;
  align-items: center;
}

.leftSide h4 {
  font-size: 24px;
  font-weight: 500;
  color: #101828;
  margin-left: 20px;
  font-family: "Inter";
}

.rightSide h4 {
  color: #475467;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  text-align: end;
  padding-bottom: 10px;
}

.gridSection {
  display: flex;
  justify-content: space-between;
  padding: 40px 0 30px;
}

.cardDetail {
  text-align: -webkit-center;
}

.cardDetail h5 {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
}
.buttonDelete {
  border: 1px solid #d0d5dd !important;
  width: 87px !important;
  height: 44px !important;
  color: #344054 !important;
  font-family: "Inter";
  font-weight: 600;
  background: #fff;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  font-size: 16px !important;
}
.buttonArchive {
  width: 115px !important;
  height: 44px;
  background: #000 !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-left: 12px;
  border-radius: 8px;
  border: 1px solid #000 !important;
}
.cardDetail h6 {
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  text-transform: capitalize;
}

table th,
td {
  font-family: "Inter";
}

.rightSide h6 {
  color: #3c8ae5;
  font-size: 24px;
  font-weight: 500;
  font-family: "Inter";
}

.rightCard {
  box-shadow: 0px 2px 4px -2px #1018280f;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  height: 97%;
  padding: 20px;
}

.paymentHead h3 {
  color: #101828;
  font-size: 24px;
  font-weight: 600;
  font-family: "Inter";
  padding-bottom: 8px;
}

.signupText {
  color: #101828 !important;
  font-size: 36px !important;
  font-family: "Inter" !important;
  font-weight: 600 !important;
}

.signupForm h5 {
  font-family: "Inter";
  font-size: 14px !important;
  font-weight: 500;
}

.signupForm label {
  font-family: "Inter";
  font-size: 14px;
  color: #344054;
  font-weight: 500;
  display: block;
  padding-bottom: 6px;
}

body .phoneNumber input {
  height: 44px !important;
  border: 0 !important;
  box-shadow: none !important;
}

.phoneNumber .ant-select-selection-item {
  font-family: "Inter" !important;
  font-weight: 500 !important;
  color: #667085 !important;
  font-size: 14px !important;
}

.generateTitle h3 {
  color: #475467;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
}

.generateTitle h1 {
  color: #101828;
  font-family: "Inter";
  font-size: 30px;
  font-weight: 600;
}

.generateTitle {
  padding: 17px 17px 0;
  display: flex;
  justify-content: space-between;
}

.lineGraph {
  background: #fff;
  border-radius: 8px;
  padding: 10px;
}

.apexcharts-legend {
  justify-content: end !important;
}

.apexcharts-menu-icon,
.apexcharts-reset-icon,
.apexcharts-zoom-icon {
  display: none !important;
}

.phoneNumber span.ant-select-arrow {
  right: 18px !important;
}

.phoneNumber input::placeholder {
  font-family: "Inter" !important;
  color: #667085 !important;
  font-size: 16px !important;
}

.phoneNumber .ant-select-selection-search {
  background-color: #efefef !important;
  border-radius: 4px;
  font-family: "Inter";
  font-weight: 600 !important;
}

.phoneNumber .ant-input-group-addon {
  border: 0 !important;
  /* background-color: #EFEFEF !important; */
  background-color: transparent !important;
}

.phoneNumber .ant-input-group-wrapper {
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
}

.signupForm button {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px !important;
  margin: 10px 0;
}

.signupForm input {
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border-radius: 8px !important;
  font-family: "Inter" !important;
  border: 1px solid #d0d5dd !important;
  color: #101828 !important;
  outline: 0 !important;
  text-transform: none !important;
}
.signupForm input::placeholder {
  color: #667085 !important;
}
.resetInput::placeholder {
  color: #667085 !important;
}
.resetInput {
  color: #000 !important;
  outline: 0 !important;
  text-transform: none !important;
}
.fonts {
  color: #667085;
  font-family: "Inter";
  font-weight: 400;
  padding: 6px 1px;
  display: block;
}

.paymentHead h4 {
  color: #475467;
  font-size: 20px;
  font-weight: 500;
  font-family: "Inter";
}

.paymentHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableModal {
  position: relative;
}

.tableModal .ant-modal-close-x {
  display: none !important;
}

.tablePayment {
  border: 1px solid #eaecf0;
  border-radius: 10px !important;
  margin: 30px 0 !important;
}

.tablePayment table thead tr th {
  font-size: 13px !important;
  color: #475467 !important;
  font-family: "Inter" !important;
  padding: 10px 16px !important;
}

.tablePayment table tbody tr td {
  font-size: 13px !important;
  color: #101828 !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}

.rightHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.revenueInput label {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  color: #344054;
  padding-bottom: 8px;
  display: block;
}

.revenueInput input {
  box-shadow: 0px 1px 2px 0px #1018280d;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #d0d5dd !important;
  color: #101828;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
}

.submitButton {
  display: flex;
  justify-content: end;
  margin: 20px 0;
}

.submitButton button {
  margin-left: 14px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  width: 96px;
  height: 44px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #344054;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 600;
}

.submitButton button:hover {
  background-color: #000 !important;
  color: #fff !important;
  border: 1px solid #000 !important;
}

.revenueInput {
  border-bottom: 1px solid #eaecf0;
  padding-bottom: 20px;
}
.sideMenu img {
  margin-right: 10px !important;
}
.sideMenu-active img {
  margin-right: 10px !important;
}
.sideMenu {
  box-shadow: 0px 1px 3px 0px #1018281a;
  border: 1px solid #eaecf0;
  margin-bottom: 20px;
  background: #fff;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  align-items: center;
  height: 90px;
  justify-content: space-between;
  padding: 0 15px;
}
.userName.last {
  text-align: end !important;
  width: auto !important;
}
.reviewTable thead tr th {
  background: #f9fafb !important;
  padding-top: 10px !important;
  padding-bottom: 11px !important;
}

.ant-checkbox .ant-checkbox-inner {
  border: 0 !important;
  background-image: url(../src/assets/images/outer.png);
  width: 20px !important;
  height: 20px !important;
  background-repeat: no-repeat;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  display: none !important;
}

.modalViewPopup button.ant-modal-close {
  display: none !important;
}

.viewModal.admins .rowBox {
  padding-bottom: 0 !important;
}

.viewPopup.modals .ant-modal-close {
  display: none;
}

.rdrStaticRangeSelected {
  background: #f9fafb;
  border-radius: 6px;
  border: 0 !important;
}

.rdrStaticRange {
  border: 0 !important;
}

.rdrStaticRangeLabel {
  font-family: "Inter" !important;
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
}

.viewModal.employ.employed .ant-select {
  width: 385px !important;
}

.viewModal.employ.employed .rowBox {
  padding-bottom: 0 !important;
}

/* .viewModal.admins {
  width: 380px !important;
} */
.borderLines {
  border-top: 1px solid #eaecf0;
  margin-bottom: 0;
  margin-top: 37px;
}

.weight {
  font-weight: 500 !important;
}

body .rowes td span.newTag {
  font-weight: 600 !important;
}

.ant-table-row-selected td {
  background: transparent !important;
}

.reviewTable tbody td:nth-child(1) {
  padding-left: 18px !important;
  padding-right: 0 !important;
}

.reviewTable.news tbody td:nth-child(7) button {
  color: #004eeb !important;
  font-weight: 600 !important;
  font-family: "Inter";
}

.reviewTable.new tbody td:nth-child(9) button {
  color: #004eeb !important;
  font-weight: 600 !important;
  font-family: "Inter";
}

.reviewTable tbody td:nth-child(11) button {
  color: #004eeb !important;
  font-weight: 600 !important;
  font-family: "Inter";
}

.reviewTable tbody td:nth-child(3) span {
  color: #101828 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
}

.reviewTable tbody td:nth-child(2) {
  color: #101828 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.reviewTable tbody td {
  height: 72px;
  color: #475467 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Inter";
}

.ant-checkbox-checked .ant-checkbox-inner {
  border: 0 !important;
  background-image: url(../src/assets/images/fill.png);
  width: 20px !important;
  height: 20px !important;
  background-color: transparent !important;
  background-repeat: no-repeat;
}

.reviewTable span.ant-dropdown-trigger {
  display: none !important;
}

.reviewTable thead th:nth-child(1) {
  padding-left: 18px !important;
  padding-right: 0 !important;
}

.reviewTable thead tr th {
  background: #f9fafb !important;
  padding-top: 10px !important;
  padding-bottom: 11px !important;
  color: #475467 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.sideMenu-active {
  box-shadow: 0px 1px 3px 0px #1018281a;
  border: 1px solid #155eef;
  margin-bottom: 20px;
  background: #fafcff;
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  align-items: center;
  height: 90px;
  justify-content: space-between;
  padding: 0 15px;
}

body {
  margin: 0;
  padding: 0;
  background-color: rgba(249, 250, 251, 1);
}

.custom-steps .ant-steps-item-content {
  background: transparent !important;
}

.navbar {
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.custom-drawer .ant-drawer-header {
  display: flex;
  justify-content: space-between;
  /* Align items to opposite ends */
}

.custom-drawer .ant-drawer-title {
  order: 2;
  /* Move the title to the right */
}

.custom-modal .ant-modal-content {
  padding: 0;
}

.ant-dropdown {
  box-shadow: none;
  padding: 0;
}

.ant-dropdown-menu {
  border: 1px solid #eaecf0 !important;
  border-radius: 8px !important;
  padding: 0 !important;
  box-shadow:
    0px 4px 6px -2px #10182808,
    0px 12px 16px -4px #10182814 !important;
  min-width: 240px;
  width: auto;
}

.ant-dropdown-menu-item {
  padding: 0 !important;
}

.ant-dropdown-menu-item:hover {
  background-color: red;
}

.ant-plot .g2-axis-label {
  display: none !important;
}

body
  > div:nth-child(4)
  > div
  > div.ant-modal-wrap.custom-modal.ant-modal-centered
  > div
  > div.ant-modal-content
  > div
  > div.py-5.px-6.border-b.flex.gap-4
  > div
  > div
  > div
  > div
  > span.ant-select-selection-item {
  font-weight: 600 !important;
}

/* body > div:nth-child(6) > div > div.ant-tooltip-content > div {
  margin-top: 20px !important;
  z-index: 10 !important;
  cursor: pointer !important;
}

body > div:nth-child(5) > div > div.ant-tooltip-content {
  margin-top: 20px !important;
  z-index: 10 !important;
  cursor: pointer !important;
} */
